/* @tailwind base; */
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

main {
  margin: 0 auto;
  max-width: 90%;
  padding: 50px 30px;
}
:root {
  --primary-color: #c5b9dd;
  --dark-primary: #8c76b6;
  --secondary-color: #f1f63dff;
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  min-width: 100%;
}

body {
  font-family: "VT323", monospace;
  letter-spacing: 0.1em;
  background: var(--primary-color);
  color: var(--dark-primary);
  cursor: url("./assets/icons/pixelated-cursor.png");
  overflow-x: hidden;
  overflow-y: scroll;
}

body:hover {
  cursor: url("./assets/icons/pointer-cursor.png");
}

header li:hover {
  /* background-color: var(--dark-primary); */
  cursor: url("./assets/icons/pointer-cursor.png");
}

nav a:hover {
  color: black;
}

a {
  text-decoration: none;
}

.view-port {
  min-height: 98vh;
}

.wrapper {
  flex: 1;
}

.bg-regex {
  background-image: url(./assets/portfolio/regex.png);
  background-size: 420px 290px;
}

.bg-tracker {
  background-image: url(./assets/portfolio/employee-tracker.png);
  background-size: 560px 400px;
}

.bg-runbuddy {
  background-image: url(./assets/portfolio/runbuddy.png);
  background-size: 380px 180px;
}

.bg-insertdisc {
  background-image: url(./assets/portfolio/insert-disc.png);
  background-size: 435px 220px;
}

.bg-oh-snap {
  background-image: url(./assets/portfolio/oh-snap.png);
  background-size: 420px 200px;
}

.bg-readme {
  background-image: url(./assets/portfolio/readme.png);
  background-size: 480px 340px;
  background-position: bottom;
}

.bg-code_it {
  background-image: url(./assets/portfolio/code_it.png);
  background-size: 400px 300px;
  background-position: inherit;
}

.headshot {
  box-shadow: 10px -10px 2px rgb(199, 192, 2);
  border: 3px solid rgb(199, 192, 2);
  border-radius: 15px;
}

.circle {
  width: 25px;
  height: 25px;
  background: lightgray;
  border: solid, black, 1px !important;
  border-radius: 25px;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
  justify-self: end;
}

.rounded {
  border-radius: 20px !important;
}

.rounded-top {
  border-top-left-radius: 17px !important;
  border-top-right-radius: 17px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
}

.border {
  border-width: 5px !important;
}

.border-bottom {
  border-bottom: 4px solid black !important;
  border-top: none;
}

.download {
  font-size: 15px;
  text-decoration: underline;
  color: blue;
}

span {
  color: black;
}

.error-text {
  color: var(--error);
}

.port-window {
  min-height: 170px;
}

.port-pane {
  min-width: 400px;
}

@media only screen and (max-width: 1100px) {
  iframe {
    height: 500px;
    width: 550px;
  }
}

@media only screen and (max-width: 690px) {
  iframe {
    height: 400px;
    width: 450px;
    margin: 0px;
  }
}

@media only screen and (max-width: 540px) {
  iframe {
    height: 400px;
    width: 325px;
  }
  img {
    width: 50px;
  }
}
